.App {
  text-align: center;
}

.menugrid {
  grid-area: menu;
}
.leftgrid {
  grid-area: left;
}
.rightgrid {
  grid-area: right;
  width: 92%;
}
.footergrid {
  grid-area: footer;
}

@media screen and (min-width: 1100px) {
  .maingrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "menu menu menu"
      "title title title"
      "left right right"
      "footer footer footer";
  }

  .left-content {
    font-size: 56px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    vertical-align: baseline;
    text-transform: uppercase;
    text-align: justify;
    margin-left: 30%;
    margin-top: 5%;
  }

  .right-content {
    font-size: 25px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    text-align: justify;
  }
}

@media screen and (max-width: 1199px) {
  .maingrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "menu menu"
      "title title"
      "left right"
      "footer footer";
  }

  .left-content {
    font-size: 56px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    vertical-align: baseline;
    text-transform: uppercase;
    text-align: justify;
    margin-left: 30%;
    margin-top: 5%;
  }

  .right-content {
    font-size: 25px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    text-align: justify;
  }
}

@media screen and (max-width: 799px) {
  .maingrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "menu"
      "title"
      "left"
      "right"
      "footer";
  }

  .left-content {
    font-size: 56px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    vertical-align: baseline;
    text-transform: uppercase;
    text-align: justify;
    margin-left: 30%;
    margin-top: 5%;
  }

  .right-content {
    font-size: 25px;
    color: white;
    font-family: helvetica, sans-serif;
    font-weight: 600;
    text-align: justify;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
