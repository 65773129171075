body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

img {
  max-width: 100%;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 10vw;
  text-shadow: black 0 0 25px;
}

h2 {
  font-size: 6vw;
  text-shadow: rgba(0, 0, 0, 0.4) 3px 6px 15px;
}

.expanded-nav-container {
  margin: 0 !important;
}

.mobile-nav-group-header {
  margin-left: 6px;
}

.info-container {
  justify-content: start;
  text-align: start;
  padding: 1.5rem;
  background-color: #686c6d;
}

.info-container h2 {
  margin: 0.25rem;
  font-size: 1.5rem;
  text-decoration: underline;
  line-height: 3rem;
}
.info-container p {
  margin: 0;
}
.footer {
  display: flex;
  justify-content: space-between;
  font-size: 1.25em;
  flex-wrap: wrap;
  justify-content: start;
  padding: 15px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 10px;
}

.footer-column h4 {
  margin: 0;
}

.footer-column p {
  line-height: 1.5;
  padding: 0;
  margin: 3px;
  font-size: 1rem;
}

.section-image {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40vh;
  justify-content: center;
  align-items: center;
  background-blend-mode: darken;
}
